export const APP_NAME = 'TMS Smart\nLeverage Your Productivity';
export const APP_SHORT_NAME = 'THD';
// export const API_GATEWAY_URL = 'http://172.16.1.125:30001';
export const API_GATEWAY_URL = 'https://thd.quantritoandien.vn/api';
export const APM_SERVER_URL = 'https://apm-thd.quantritoandien.vn';
export const SAP_SERVICE_URL = 'http://172.16.1.142/api';
export const WINFORM_URL = 'http://172.16.1.142:8080';

export const SOCKET_GATEWAY_URL = 'https://thd.quantritoandien.vn';
export const SOCKET_NAMESPACE = '/messages';
export const SOCKET_PATH = '/ws/socket.io';

export const ITEM_AMOUNT_PER_PAGE_VALUES = [5, 15, 30, 50, 100, 200];
export const ITEM_AMOUNT_PER_PAGE = 30;
export const PAGE_RANGE_DISPLAYED = 5;
export const SEPARATE_STRING = ' - ';
export const UNLIMITED_RETURNED_RESULT = 9999;
export const INSTANT_SEARCH_RETURNED_RESULT = 10;

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const MONTH_FORMAT = 'MM/YYYY';
export const QUARTER_FORMAT = 'QQQ/YYYY';
export const DATETIME_FORMAT = DATE_TIME_FORMAT;

export const UPLOAD_FILE_MAX_SIZE = 419000000;
export const UPLOAD_FILE_EXTENSION = '.png, .jpg, .doc, .xls, .docx, .xlsx, .pdf, .zip, .rar, .7zip, .msg, .csv, .gz, .hbs, .json';

export const PRIMARY_COLOR = 'blue';
export const PRIMARY_COLOR_CODE = '#2185d0';

export const SECONDARY_COLOR = 'red';
export const SECONDARY_COLOR_CODE = '#C00000';

export const THIRD_COLOR = 'black';
export const THIRD_COLOR_CODE = '#333';

export const VALIDATE_FAILURE = 'msg.validate.failure';

export const PENDING_EVENT_COLOR = '#888888';
export const PROCESS_EVENT_COLOR = '#2185d0';

export const MEETING_ROOM_EVENT_COLOR = '#3174ad';
export const VEHICLE_EVENT_COLOR = '#2ca02c';
