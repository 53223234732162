import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Grid, Form, Container, Button, Divider, Input, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Buffer } from 'buffer';

import { APP_NAME, API_GATEWAY_URL, SECONDARY_COLOR_CODE } from '../../../constants/config';

import {
  setToken,
  setUserId, setUserName, setFullName, setEmployeeNo,
  setSignatureImage,
} from '../../../helpers/commonHelper';

import { getToken, removeToken } from '../../../helpers/commonHelper';

class Layout extends Component {
  static get propTypes() {
    return {
      refUrl: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      refUrl: '',
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      password: '',
      captchaValue: '',
      failedCount: 0,
      error: false,
      loading: false,
      messages: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    const token = getToken();

    if (token) {
      try {
        const data = {
          moduleCode: 'home',
        };

        const result = await axios({
          method: 'POST',
          data,
          url: `${API_GATEWAY_URL}/v1/users/ping`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { refUrl } = this.props;

        if (refUrl) {
          window.location.href = decodeURIComponent(refUrl);
        } else {
          const { moduleList, currentModuleCode } = result.data.data;

          if (currentModuleCode) {
            window.location.href = `/${currentModuleCode}/`;
            return;
          } else if (moduleList.length) {
            const firstModule = moduleList.find(m => (m.moduleOrder > 0));
            const { moduleCode } = firstModule;

            if (firstModule) {
              window.location.href = `/${moduleCode}/`;
              return;
            }
          }

          removeToken();
          window.location.href = '/';
        }
      } catch (error) {
        removeToken();
        window.location.href = '/';
      }
    }
  }

  onChange(event, data) {
    event.preventDefault();

    const { name, value } = data;
    this.setState({ [name]: value });
  }

  async onSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    const userName = this.state.userName.trim().toLowerCase();
    const password = this.state.password.trim();

    if (!userName || !password) {
      this.setState({
        loading: false,
        error: true,
        messages: 'Vui lòng nhập đầy đủ tài khoản & mật khẩu.', // TODO: change to i18n
      });

      return;
    }

    const failedCount = this.state.failedCount;
    const captchaValue = this.state.captchaValue;
    const captchaText = this.state.captchaText;

    if (failedCount > 1 && captchaValue !== captchaText) {
      this.setState({
        loading: false,
        error: true,
        messages: 'Nhập sai mã Captcha', // TODO: change to i18n
      });
      return
    }

    const { refUrl } = this.props;

    const user = {
      // applicationCode: 'bos', // send this to filter application related function / module list
      userName,
      password,
    };

    try {
      const result = await axios({
        method: 'POST',
        data: { credentials: user },
        url: `${API_GATEWAY_URL}/v1/users/login`,
      });

      const {
        _id, token, fullName,
        moduleList, employeeNo,
        defaultFunctionUrl,
        signatureImageFileId,
      } = result.data.data;

      setToken(token);
      setUserId(_id);
      setUserName(userName);
      setFullName(fullName);
      setEmployeeNo(employeeNo);

      if (signatureImageFileId) {
        const signatureResult = await axios({
          method: 'GET',
          responseType: 'arraybuffer',
          url: `${API_GATEWAY_URL}/v1/files/${signatureImageFileId}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const base64Image = Buffer.from(signatureResult.data, 'binary').toString('base64');
        setSignatureImage(base64Image);
      }

      if (refUrl) {
        window.location.href = decodeURIComponent(refUrl);
      } else {
        if (defaultFunctionUrl) {
          window.location.href = defaultFunctionUrl;
          return;
        } else if (moduleList.length) {
          const firstModule = moduleList.find(m => (m.moduleOrder > 0));
          const { moduleCode } = firstModule;

          if (firstModule) {
            window.location.href = `/${moduleCode}/`;
            return;
          }
        }

        this.setState({
          loading: false,
          error: true,
          messages: 'Không lấy được phân quyền.', // TODO: change to i18n
        });
      }
    } catch (error) {
      let failedCaptcha = false;
      if (this.state.captchaValue && this.state.failedCount > 2) {
        const captchaText = this.state.captchaText.trim();
        const captchaValue = this.state.captchaValue.trim();
        if (captchaText !== captchaValue) failedCaptcha = true;
      }
      this.setState({
        loading: false,
        captcha: error.response.data.captcha ? error.response.data.captcha.data : '',
        failedCount: error.response.data.failedCount ? error.response.data.failedCount : 0,
        captchaText: error.response.data.captcha ? error.response.data.captcha.text : '',
        error: true,
        messages: `Có lỗi xảy ra: ${failedCaptcha ? 'Nhập sai mã Captcha' : error.response.data.error.message}`, // TODO: change to i18n
      });
    }
  }

  render() {
    const {
      userName, password,
      loading, error, messages,
      captchaValue, failedCount, captcha
    } = this.state;

    return (
      <div className="login-form">
        <style>
          {`
            body > div,
            body > div > div,
            body > div > div > div.login-form {
              height: 100%;
            }

            body {
              background-image: url("images/background.jpg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
        `}
        </style>
        <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle" >
          <Grid.Column style={{ position: 'absolute', top: '50%', left: '50%', marginLeft: '-225px', marginTop: '-340px', width: '450px', overflow: 'hidden', background: 'rgb(255,255,255,0.7)', borderRadius: '0.3em', paddingRight: '1em !important' }}>
            <Grid centered textAlign="center" className="logo">
              <Image src="/images/thd-logo.png" alt="logo" style={{ maxWidth: 1000, maxHeight: 150 }} />
              <span style={{ fontSize: '24px', fontWeight: 'bold', textTransform: 'uppercase', lineHeight: '32px', color: '#372d73', whiteSpace: 'pre-line' }}>{APP_NAME}</span>
            </Grid>
            <Divider hidden />

            <Form onSubmit={this.onSubmit} className="attached fluid segment login" style={{ backgroundColor: 'transparent', border: 'none' }}>
              <Form.Field control={Input} name="userName" value={userName} onChange={this.onChange} fluid icon="user" placeholder="Tài khoản" />
              <Form.Field control={Input} name="password" value={password} onChange={this.onChange} fluid icon="lock" placeholder="Mật khẩu" type="password" />
              { failedCount > 1 && captcha && <div className="captcha" dangerouslySetInnerHTML={{ __html: captcha }} />}
              { failedCount > 1 && captcha && <Form.Field style={{width: 200}} control={Input} name="captchaValue" value={captchaValue} onChange={this.onChange} fluid placeholder="Nhập captcha" />}
              <Container textAlign="center">
                <Button type="submit" loading={loading} name="login" >Đăng nhập</Button>
              </Container>
              
            </Form>

            {/* TODO: case return "system:msg.httpResponseCode.502" */}
            <p style={{ color: SECONDARY_COLOR_CODE, fontWeight: 'bold' }}>{error ? messages : ''}</p>

            <Divider hidden style={{ height: '20px' }} />

            <span style={{ color: '#000' }}>Bản quyền © bởi TSC Services.</span>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Layout);
